<template>
  <div>
    <div class="wechat-tips" v-if="isWechat">
      <img
        src="@/assets/images/download/825.png"
        alt="下载页背景图"
        class="new-market-bg"
      />
    </div>

    <div class="toutiao-tips" v-if="isToutiao">
      <img
        src="@/assets/images/download/101.png"
        alt="下载页背景图"
        class="new-market-bg"
      />
    </div>

    <div class="new-market-page">
      <img
        src="@/assets/images/download/826-1.png"
        alt="下载页背景图"
        class="new-market-bg"
      />

      <div style="position: relative">
        <!--<img-->
          <!--src="@/assets/images/download/818.png"-->
          <!--alt="下载页背景图"-->
          <!--class="new-market-bg"-->
        <!--/>-->

        <div class="new-market-content">
          <a
            id="downloadAndroid"
            class="new-market-download"
            href="javascript:;"
            @click="openOrDownload"
            download="new_market"
          >
            <img
              src="@/assets/images/download/android.png"
              alt="下载图标"
              class="new-market-icon"
            />
            <div>
              <p>Android下载</p>
            </div>
          </a>
          <a
            class="new-market-download"
            href="javascript:;"
            @click="openOrDownload2"
            download="new_market"
          >
            <img
              src="@/assets/images/download/yingyongshch.png"
              alt="下载图标"
              class="new-market-icon"
            />
            <div>
              <p>Android应用市场</p>
            </div>
          </a>
          <!--1621000959  ios开发者后台APPid-->
          <a
            class="new-market-download disabled"
            href="itms-apps://itunes.apple.com/tw/app/id1621000959"
            @click="checkIos"
          >
            <img
              src="@/assets/images/download/ios.png"
              alt="下载图标"
              class="new-market-icon"
            />
            <div>
              <p>前往AppStore</p>
            </div>
          </a>
        </div>
      </div>

      <img
        src="@/assets/images/download/97-1.png"
        alt="下载页背景图"
        class="new-market-bg"
      />

      <img
        src="@/assets/images/download/98-1.png"
        alt="下载页背景图"
        class="new-market-bg"
      />

      <img
        src="@/assets/images/download/99-1.png"
        alt="下载页背景图"
        class="new-market-bg"
      />
    </div>
  </div>
</template>

<script>
import MobileDetect from '@/assets/js/mobile-detect.js'
import { inviteRecord } from '@/api/channel'
export default {
  metaInfo: {
    title: '宝秤农资-下载中心'
  },
  data () {
    return {
      isWechat: false,
      isToutiao: false,
      isIos: false
    }
  },
  created () {
    this.isWechat = this.$isWechat()
    this.isToutiao = this.$isToutiao()
    this.isIos = this.$isIos()

    const deviceType = navigator.userAgent // 获取userAgent信息
    const md = new MobileDetect(deviceType) // 实例化mobile-detect
    let os = md.os() // 获取系统
    if (os === 'iOS') {
      // ios系统的处理
      os = md.os() + md.version('iPhone')
    } else if (os === 'AndroidOS') {
      // Android系统的处理
      os = md.os() + md.version('Android')
    }
    const deviceInfo = `${window.screen.height}-${window.screen.width}-${
      navigator.language
    }-${os || 'other'}`

    inviteRecord({
      channel: this.$route.query.channel,
      device_info: deviceInfo,
      scene: '1'
    })
  },
  methods: {
    checkIos () {
      if (!this.isIos) {
        this.$toast('请在IOS系统中打开')
      }
    },
    openOrDownload () {
      // 直接唤醒app
      window.location.href = 'newnzmarket://'
      // 下载
      setTimeout(() => {
        if (!document.hidden) {
          window.open('https://static-bc.suzi.com.cn/download/youtian.apk', '_self')
        }
      }, 1000)
    },
    openOrDownload2 () {
      // 打开应用市场
      window.location.href = 'market://details?id=youtian.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.new-market-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px;
  color: #87a89f;
}

.new-market-page {
  background-color: #fff;
}

.new-market-content {
  position: absolute;
  top: -42.2667vw;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px 0;
}

.new-market-download {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 15px;
  height: 11.7333vw;
  width: 44.2667vw;
  background: #00a0ac;
  color: #fff;
  font-size: 3.7333vw;
  border-radius: 1.3333vw;
}

.new-market-download.disabled {
  color: #00a0ac;
  background: #e3edee;
}

.new-market-download .new-market-icon {
  height: 6.4vw;
  width: 6.4vw;
  padding: 15px 10px;
}

.toutiao-tips,
.wechat-tips {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
}

.new-market-bg {
  width: 100%;
}

.new-market-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
